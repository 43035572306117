import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Animation } from "./animation";

import './work-list.scss';

const query = graphql`
  {
    allOurWork {
      edges {
        node {
          title
          description_short
          slug
          icons {
            large
            small
          }
        }
      }
    }
  }
`

export const WorkList = ({ current }) => {
  let {
    allOurWork: { edges },
  } = useStaticQuery(query)

  edges = edges.filter(a => a.node.slug !== current);

  return (
    <div className="work-items">
      {edges.map(edge => {
        const workItem = edge.node
        return (
          <Link
            className="work-item"
            style={{
              flex: `0 0 ${100/edges.length}%`,
              width: `${100/edges.length}%`
            }}
            key={workItem.slug}
            to={`/our-work/${workItem.slug}`}
          >
            <div>
              <Animation name={workItem.icons.small} />
              <div>
                <h3>{workItem.title}</h3>
                <p>{workItem.description_short}</p>
              </div>
            </div>
          </Link>
        )
      })}
    </div>
  )
}
