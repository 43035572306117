import React, { useEffect, useRef} from 'react';
import lottie from 'lottie-web';
import { useStaticQuery, graphql } from "gatsby"


const query = graphql`
{
  allAnimation {
    edges {
      node {
        name,
        lottiData
      }
    }
  }
}
`


export const Animation = ({ name }) => {
  const data = useStaticQuery(query)
  const { allAnimation: { edges = [] } } = data;
  const icon = edges.map((a) => a.node).find(a => a.name === name);




  const ref = useRef(null);
  let animation = null;

  const onHover = () => {
    if(animation){
      animation.setDirection(1)
      animation.play();
    }
  }

  const onHoverLeave = () => {
    if(animation){
      animation.setDirection(-1)
      animation.play();
    }
  }

  useEffect(() => {
    if(!icon) {
      return (null);
    }
    // eslint-disable-next-line
    animation = lottie.loadAnimation({
      container: ref.current,
      renderer: 'svg',
      animationData: JSON.parse(icon.lottiData),
      autoplay: false,
      loop: false
    })
    animation.setSpeed(2);
    return () => {
      animation.destroy();
    }
  }, [ref])


  return (
    // eslint-disable-next-line
    <div className="animated-icon" onMouseOver={onHover} onFocus={onHover} onMouseLeave={onHoverLeave} onBlur={onHoverLeave}>
      <div ref={ref} />
    </div>
  )
}